/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/theming";

@include mat-core();

@import "./themes/cega-default.scss";
@import "~material-design-icons/iconfont/material-icons.css";

@import "../../global-styles.scss";

body {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin: 0;
    font-size: 16px;
}
