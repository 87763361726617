.error-notification {
    color: #d8000c;
    background-color: #ffd2d2;
}

.warn-notification {
    color: #9f6000;
    background-color: #feefb3;
}

.success_notification {
    color: #4f8a10;
    background-color: #dff2bf;
}

.info-notification {
    color: #00529b;
    background-color: #bde5f8;
}
